import { useMemo, useState } from "react";
import { useContentContext } from "../../contexts/ContentContext";
import queryString from "query-string";
import PdpPageModel, { PdpConfig } from "./ProductDetailsPage.pageModel";
import { DisplayConfig } from "../../modules/Product/product.moduleModels";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { isAuthenticated } from "../../utils";
import { getCategoryRoute, getProductTypeRoute } from "../../selectors";
import { useProductContext } from "../../contexts/ProductContext";

export const useComponentModel = () => {
  const {
    templateData: { productDetail },
  } = useContentContext();
  const { productPageDisplayConfiguration, innerElements: dynamicContentElements = [] } = productDetail || {};

  const pdpConfig: PdpConfig = JSON.parse(productPageDisplayConfiguration || "{}"); //Changes - accessing the configuration for PDP

  // Add config override for displayConfig here
  const displayConfig: DisplayConfig = useMemo(
    () => ({
      showInputLabel: !!pdpConfig?.showInputLabel,
      showFavoritesButton: !!pdpConfig?.enableFavorites,
      showInventory: !!pdpConfig?.input?.showInventory,
      showRelatedProducts: !!pdpConfig?.showRelatedProducts,
      optionsStyle: pdpConfig?.options?.style || "dropdown",
      quantityInputMode: pdpConfig?.input?.quantityInput || "text",
      priceShowCriteria: pdpConfig?.price?.showCriteria || "Always",
      defaultSkuOnPageLoad: pdpConfig?.defaultSkuOnPageLoad || false,
      showExistingQuoteAndLists: !!pdpConfig?.showExistingQuoteAndLists,
      breadcrumbsType: pdpConfig?.breadcrumbsType || "Product type",
    }),
    [pdpConfig],
  );

  const location = useLocation();
  const [selection, setSelection] = useState<{ [optionGroupCode: string]: string }>({});
  const [skuConfiguration, setSkuConfiguration] = useState({});
  const productContext = useProductContext();
  const productTypeBase = useSelector((state: any) => state.configuration.filtering.productTypeBase) as string;
  const productTypeRoute = useSelector(getProductTypeRoute) as string;
  const categoryRoute = useSelector(getCategoryRoute);
  const siteSetting = useSelector((state: any) => state.configuration.site.settings);
  const params = useMemo(
    () => queryString.parse(location.search, { arrayFormat: "separator", arrayFormatSeparator: "," }),
    [location.search],
  );

  const componentData = useMemo(
    () =>
      new PdpPageModel({
        isCmsPageLoading: !productDetail,
        productContext,
        productTypeBase,
        productTypeRoute,
        categoryRoute,
        displayConfig,
        dynamicContentElements,
        sitePurpose: siteSetting.sitePurpose,
        params,
        isAuthenticated: !!isAuthenticated(),
        selection,
        setSelection,
        skuConfiguration,
        setSkuConfiguration,
      }),
    [
      productDetail,
      displayConfig,
      dynamicContentElements,
      params,
      productContext,
      productTypeBase,
      categoryRoute,
      productTypeRoute,
      selection,
      siteSetting.sitePurpose,
      skuConfiguration,
      setSkuConfiguration,
    ],
  );
  // Add config override for product detail here
  return { componentData };
};
