import { useContentContext } from "../../contexts/ContentContext";

import { useElementContext } from "../../contexts";

const Shop = () => {
  const pageData = useContentContext();
  const {
    ProductListingModule,
    PageModule: { DynamicPage },
    CommonModule: { PageContentRow },
  } = useElementContext();

  const { searchListing } = pageData.templateData || {};
  if (!searchListing) return <DynamicPage />;

  return (
    <DynamicPage>
      {({ renderDynamicContent }) => (
        <>
          <PageContentRow>
            <ProductListingModule.CoreComponents.SearchListing {...searchListing} />
          </PageContentRow>
          {renderDynamicContent()}
        </>
      )}
    </DynamicPage>
  );
};

export default Shop;
