import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export interface RegisterSpecialMenuProps {
  color?: string;
  cssClass?: string;
  linkLabel?: string;
}

const RegisterSpecialMenu = ({ color = "", cssClass = "", linkLabel = "Register" }: RegisterSpecialMenuProps) => {
  const user = useSelector((state: any) => state.userReducer);
  const { accountID } = user;

  if (!!accountID) return null;
  return (
    <Link
      to={"/register"}
      className={`p-2 link-button h-100 d-flex align-items-center align-self-lg-center user-register ${cssClass}`}
      style={{ color: color }}
    >
      <span className={"d-flex align-items-center text-center"}>
        <i className="bi bi-person-plus-fill me-2 fs-4"></i>
        <span className="d-block fw-bold">{linkLabel}</span>
      </span>
    </Link>
  );
};

export { RegisterSpecialMenu };
