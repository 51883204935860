import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import queryString from "query-string";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useUtilities, useFormatDate } from "../../hooks";
import { useElementContext, useServiceContext } from "../../contexts";

const BlogListing = ({ contentElementName, pageSize = 6, customStyleClasses }) => {
  const {
    CommonModule: { ListingPagination },
  } = useElementContext();
  const { StorefrontContentService } = useServiceContext();
  const { t } = useTranslation();
  const [formateDate] = useFormatDate();
  let { eventHandlerForWSIWYG } = useUtilities();
  const location = useLocation();
  const navigate = useNavigate();
  const params = queryString.parse(location.search);

  const [blogs, setBlogs] = useState([]);
  const [isLoaded, setLoaded] = useState(false);
  const [pagination, setPagination] = useState({
    currentPage: params.page ? parseInt(params.page, 10) : 1,
    totalPages: 1,
    recordsCount: 0,
  });

  const siteSetting = useSelector((state) => state.configuration.site.settings);
  const service = useMemo(() => new StorefrontContentService(), [StorefrontContentService]);

  useEffect(() => {
    if (!params.page) {
      if (pagination.currentPage > 1) {
        navigate({
          pathname: location.pathname,
          search: queryString.stringify({ ...params, page: pagination.currentPage }, { arrayFormat: "comma" }),
        });
      }
    } else if (parseInt(params.page, 10) !== pagination.currentPage) {
      setPagination((prevState) => ({
        ...prevState,
        currentPage: parseInt(params.page, 10),
      }));
    }
  }, [params, navigate, pagination.currentPage, location.pathname]);

  useLayoutEffect(() => {
    const fetchBlogs = async () => {
      const res = await service.fetchCollectionList("contents", {
        "pagination[page]": pagination.currentPage,
        "pagination[pageSize]": pageSize,
        populate: "*",
      });
      const {
        meta: {
          pagination: { pageCount, page, total },
        },
        data,
      } = res;
      setPagination({
        currentPage: page,
        totalPages: pageCount,
        recordsCount: total,
      });
      setBlogs(data);
      setLoaded(true);
    };
    fetchBlogs();
  }, [service, pagination.currentPage, pageSize]);

  const setPage = useCallback(
    (page) => {
      setPagination((prevState) => ({ ...prevState, currentPage: page }));
      const newParams = { ...params, page };
      if (page === 1) delete newParams["page"];
      navigate({
        pathname: location.pathname,
        search: queryString.stringify(newParams, { arrayFormat: "comma" }),
      });
    },
    [location.pathname, params, navigate],
  );

  return (
    <section className={`newsSection ${customStyleClasses}`}>
      <div className="container px-0">
        {contentElementName && (
          <header className="section-title">
            <h2>{contentElementName}</h2>
          </header>
        )}
        <div className="row">
          {blogs.map((data) => {
            return (
              <div key={data.title} className={`col-md-6 col-lg-4 d-flex px-0`}>
                <article className="blog-card card border shadow m-3 w-100">
                  <img
                    src={data.Thumbnail?.url || data.postImage?.url}
                    className="mw-100"
                    type="blog"
                    alt={data.title}
                  />
                  <div className="card-body p-4">
                    {data.contentPublicationDate && (
                      <p className="pb-2">
                        <small className="text-muted fst-italic ">{formateDate(data.contentPublicationDate)}</small>
                      </p>
                    )}
                    <h4 className="py-0">
                      <Link to={`/${siteSetting.blogPageSlug}/${data.slug}`}>{data.title}</Link>
                    </h4>
                    <p onClick={eventHandlerForWSIWYG} dangerouslySetInnerHTML={{ __html: data.SubText }} />
                  </div>
                </article>
              </div>
            );
          })}
          {isLoaded && blogs.length === 0 && <h3 className="text-center">{t("frontend.blog.none")}</h3>}
          <ListingPagination {...pagination} setPage={setPage} />
        </div>
      </div>
    </section>
  );
};

export { BlogListing };
