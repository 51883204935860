import OverviewDraftOrder from "../../components/Account/AccountOverview/OverviewDraftOrders";
import { useElementContext } from "../../contexts";

const DraftOrderList = () => {
  const {
    CommonModule: { AccountContent, AccountLayout },
  } = useElementContext();
  return (
    <AccountLayout>
      <AccountContent />
      <OverviewDraftOrder />
    </AccountLayout>
  );
};

export default DraftOrderList;
