import { toast } from "react-toastify";
import quertString from "query-string";
import { SlatwalApiService, axios, setupSlatwalApiService } from "../services";
import { getCurrentSiteCode, getErrorMessage } from "../utils";
import { receiveCart, requestCart } from "./";
import { receiveSubscriptionCart, requestSubscriptionCart } from "./subscriptionCartActions";
import { getWishLists, receiveUser, requestUser } from "./userActions";
import { LocalStorageService } from "../utils/localStorage";

export const REQUEST_CONFIGURATION = "REQUEST_CONFIGURATION";
export const RECIVE_CONFIGURATION = "RECIVE_CONFIGURATION";
export const SET_TITLE = "SET_TITLE";
export const SET_TITLE_META = "SET_TITLE_META";

const serviceDomain = process.env.REACT_APP_DATASTORE_URL;
const siteDomain = window.getSiteConfig().domain;

const adminOverride = process.env.REACT_APP_ADMIN_URL_OVERRIDE || false;

export const setTitle = (title = "") => {
  return {
    type: SET_TITLE,
    title,
  };
};
export const reciveConfiguration = (config) => {
  return {
    type: RECIVE_CONFIGURATION,
    config,
  };
};
export const requestConfiguration = () => {
  return {
    type: REQUEST_CONFIGURATION,
  };
};

export const getConfiguration = (
  siteCode = getCurrentSiteCode(),
  returnJSONObjects = "cart,account,orderTemplateCart",
) => {
  return async (dispatch, getState) => {
    dispatch(requestConfiguration());
    dispatch(requestSubscriptionCart());
    dispatch(requestUser());
    dispatch(requestCart());

    let configUrl = `${serviceDomain}/config?${quertString.stringify({
      concern: siteDomain,
      topic: "reactStorefront",
    })}`;
    if (process.env.REACT_APP_DELTA_STORE_URL) {
      configUrl = `${process.env.REACT_APP_DELTA_STORE_URL}/config`;
    }

    const { data } = await axios(configUrl);
    const serverConfig = data.config;
    if (adminOverride && process.env.NODE_ENV !== "production") {
      console.log(`Overriding admin url to ${adminOverride}`);
      data.integrations.commerce.moduleApiUrl = adminOverride;
    }
    window.UC = { integrations: data.integrations };
    LocalStorageService.setItem("appConfiguration", {
      currentSite: window.UC.integrations?.commerce.siteCode,
      sites: [],
    });
    dispatch(reciveConfiguration(serverConfig));

    setupSlatwalApiService();
    SlatwalApiService.content.getConfiguration({ returnJSONObjects }).then((response) => {
      if (response.isSuccess() && Object.keys(response.success()?.errors || {}).length)
        toast.error(getErrorMessage(response.success().errors));

      if (response.isSuccess()) {
        dispatch(receiveUser(response.success().account));
        if (response.success()?.account?.accountID?.length) dispatch(getWishLists());
        dispatch(receiveCart(response.success().cart));
        if (response.success()?.orderTemplateCart) {
          dispatch(receiveSubscriptionCart(response.success()?.orderTemplateCart));
        } else {
          dispatch(receiveSubscriptionCart({}));
        }
      }
    });
  };
};

export const setSelectedCartOrQuotes = (cartOrQuote) => {
  return async (dispatch) => {
    localStorage.setItem("selectedCartOrQuote", JSON.stringify(cartOrQuote));
    dispatch({ type: "GET_CART_OR_QUOTE", ...cartOrQuote });
  };
};
